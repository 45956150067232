import React, { useCallback } from "react";
import { Form, Input, SubmitButton, Cascader, Select } from "formik-antd";
import { useTranslation } from "react-i18next";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { Integration, Mapping, ReportColumnType } from "/app/src/models";
import { cascadeReportColumnTypes } from "/app/src/helpers/cascadeReportColumnTypes";
import HeroButton from "/app/src/components/HeroUi/Button";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import getMappingTitle from "./utilities";

/**
 * Component for a column type mapping.
 */
export function ColumnType({
  isThemed,
  columnTypes,
  mapping,
  removeMapping,
  dirty,
  isValid,
}: {
  isThemed: boolean;
  columnTypes: ReportColumnType[];
  mapping?: Mapping;
  removeMapping?: (mapping: Mapping) => void;
  dirty: boolean;
  isValid: boolean;
}) {
  /**
   * Remove the mapping. This is only available when editing a mapping.
   */
  const removeMappingHandler = useCallback(() => {
    if (removeMapping) {
      removeMapping(mapping);
    }
  }, [removeMapping, mapping]);
  const { t } = useTranslation();
  return (
    <div className="flex gap-4 mt-6">
      {mapping && (
        <div className="w-1/12 mb-6 flex items-center justify-center">
          {t("translation:column")}:
        </div>
      )}
      <div className="w-7/12">
        <Form.Item hasFeedback={false} name="key">
          <Input
            size="large"
            name="key"
            placeholder={t("translation:enter_key")}
          />
        </Form.Item>
      </div>
      <div className="w-1/12 mb-6 flex items-center justify-center">
        <IconBuilder icon="ArrowRight" size={36} />
      </div>
      <div className="w-5/12">
        <Form.Item hasFeedback={false} name="columnTypeId">
          {isThemed ? (
            <Cascader
              name="columnTypeId"
              size="large"
              className="larger-cascade"
              options={cascadeReportColumnTypes(columnTypes)}
              placeholder={t("translation:select_column")}
              showSearch
            />
          ) : (
            <SearchFilter
              list={columnTypes}
              name="columnTypeId"
              placeholder={t("translation:select_column")}
            />
          )}
        </Form.Item>
      </div>
      <div className="w-3/12 flex gap-2">
        <SubmitButton
          type="primary"
          size="large"
          block
          disabled={!dirty || !isValid}
        >
          {t("translation:save")}
        </SubmitButton>
        {mapping && (
          <HeroButton
            onClick={removeMappingHandler}
            color="default"
            size="md"
            variant="bordered"
            isIconOnly
            className="hover:border-red-500 bg-white"
          >
            <IconBuilder size={18} color="#e00000" icon="Delete" />
          </HeroButton>
        )}
      </div>
    </div>
  );
}

/**
 *
 * @param param0 mapping: Mapping
 * @returns Draggable Mapping State
 */
export function DraggableMappingType({
  mapping,
  integration,
}: {
  mapping?: Mapping;
  integration?: Integration;
}) {
  const type = getMappingTitle(mapping, integration);

  return (
    <div className="flex my-6 items-start">
      <div className="flex items-start">{type}</div>
    </div>
  );
}

/**
 * Component for customer's internal id mapping.
 */
export function InternalIdType({
  mapping,
  removeMapping,
  dirty,
  isValid,
}: {
  mapping?: Mapping;
  removeMapping?: (mapping: Mapping) => void;
  dirty: boolean;
  isValid: boolean;
}) {
  const { t } = useTranslation();
  /**
   * Remove the mapping. This is only available when editing a mapping.
   */
  const removeMappingHandler = useCallback(() => {
    if (removeMapping) {
      removeMapping(mapping);
    }
  }, [removeMapping, mapping]);
  return (
    <div className="flex gap-4 mt-6">
      {mapping && (
        <div className="w-1/12 mb-6 flex items-center justify-center">
          {t("translation:id")}:
        </div>
      )}
      <div className="w-7/12">
        <Form.Item hasFeedback={false} name="key">
          <Input
            size="large"
            name="key"
            placeholder={t("translation:enter_key")}
          />
        </Form.Item>
      </div>
      <div className="w-1/12 mb-6 flex items-center justify-center">
        <IconBuilder icon="ArrowRight" size={36} />
      </div>
      <div className="w-5/12">
        <Form.Item hasFeedback={false} name="value">
          <Input
            disabled
            size="large"
            name="value"
            placeholder={t("translation:internal_id")}
          />
        </Form.Item>
      </div>
      <div className="w-3/12 flex gap-2">
        <SubmitButton
          type="primary"
          size="large"
          block
          disabled={!dirty || !isValid}
        >
          {t("translation:save")}
        </SubmitButton>
        {mapping && (
          <HeroButton
            onClick={removeMappingHandler}
            color="default"
            size="md"
            variant="bordered"
            isIconOnly
            className="hover:border-red-500 bg-white"
          >
            <IconBuilder size={18} color="#e00000" icon="Delete" />
          </HeroButton>
        )}
      </div>
    </div>
  );
}

/**
 * Component for a grouping type mapping. This is useful for nested data.
 */
export function GroupingType({
  dirty,
  removeMapping,
  mapping,
  label,
}: {
  dirty: boolean;
  removeMapping?: (mapping: Mapping) => void;
  mapping?: Mapping;
  label?: string;
}) {
  const { t } = useTranslation();

  /**
   * Remove the mapping. This is only available when editing a mapping.
   */
  const removeMappingHandler = useCallback(() => {
    if (removeMapping) {
      removeMapping(mapping);
    }
  }, [removeMapping, mapping]);

  return (
    <div className="flex gap-4 mt-6">
      {mapping && (
        <div className="w-1/12 mb-6 flex items-center justify-center">
          {label}:
        </div>
      )}
      <div className="w-8/12">
        <Form.Item hasFeedback={false} name="key">
          <Input
            size="large"
            name="key"
            placeholder={t("translation:enter_key")}
          />
        </Form.Item>
      </div>
      <div className="w-3/12 flex gap-2">
        <SubmitButton type="primary" size="large" block disabled={!dirty}>
          {t("translation:save")}
        </SubmitButton>
        {mapping && (
          <HeroButton
            onClick={removeMappingHandler}
            color="default"
            size="md"
            variant="bordered"
            isIconOnly
            className="hover:border-red-500 bg-white"
          >
            <IconBuilder size={18} color="#e00000" icon="Delete" />
          </HeroButton>
        )}
      </div>
    </div>
  );
}
/**
 * Component for an advanced type mapping. This is useful for amalgamating multiple keys into a single column.
 */
export function AdvancedType({
  mapping,
  removeMapping,
  dirty,
  isValid,
  isThemed,
  columnTypes,
}: {
  mapping?: Mapping;
  removeMapping?: (mapping: Mapping) => void;
  dirty: boolean;
  isValid: boolean;
  isThemed: boolean;
  columnTypes: ReportColumnType[];
}) {
  const { t } = useTranslation();
  /**
   * Remove the mapping. This is only available when editing a mapping.
   */
  const removeMappingHandler = useCallback(() => {
    if (removeMapping) {
      removeMapping(mapping);
    }
  }, [removeMapping, mapping]);
  return (
    <div className="flex gap-4 mt-6">
      {mapping && (
        <div className="w-1/12 mb-6 flex items-center justify-center">
          {t("translation:advanced")}:
        </div>
      )}
      <div className="w-7/12">
        <Form.Item hasFeedback={false} name="key">
          <Select
            size="large"
            name="key"
            mode="tags"
            placeholder={t("translation:enter_key")}
            open={false}
          />
        </Form.Item>
      </div>
      <div className="w-1/12 mb-6 flex items-center justify-center">
        <IconBuilder icon="ArrowRight" size={36} />
      </div>
      <div className="w-5/12">
        <Form.Item hasFeedback={false} name="columnTypeId">
          {isThemed ? (
            <Cascader
              name="columnTypeId"
              size="large"
              className="larger-cascade"
              options={cascadeReportColumnTypes(columnTypes)}
              placeholder={t("translation:select_column")}
              showSearch
            />
          ) : (
            <SearchFilter
              list={columnTypes}
              name="columnTypeId"
              placeholder={t("translation:select_column")}
            />
          )}
        </Form.Item>
      </div>
      <div className="w-3/12 flex gap-2">
        <SubmitButton
          type="primary"
          size="large"
          block
          disabled={!dirty || !isValid}
        >
          {t("translation:save")}
        </SubmitButton>
        {mapping && (
          <HeroButton
            onClick={removeMappingHandler}
            color="default"
            size="md"
            variant="bordered"
            isIconOnly
            className="hover:border-red-500 bg-white"
          >
            <IconBuilder size={18} color="#e00000" icon="Delete" />
          </HeroButton>
        )}
      </div>
    </div>
  );
}

/*
 * Component for a report column type mapping.
 */
export function ReportColumnTypes({
  mapping,
  removeMapping,
  dirty,
  isValid,
}: {
  mapping?: Mapping;
  removeMapping?: (mapping: Mapping) => void;
  dirty: boolean;
  isValid: boolean;
}) {
  const { t } = useTranslation();
  const removeMappingHandler = useCallback(() => {
    if (removeMapping) {
      removeMapping(mapping);
    }
  }, [removeMapping, mapping]);

  return (
    <div className="flex gap-4 mt-6">
      {mapping && (
        <div className="w-2/12 mb-6 flex items-center justify-center">
          {t("translation:reportColumn")}:
        </div>
      )}
      <div className="w-7/12">
        <Form.Item hasFeedback={false} name="key">
          <Input
            size="large"
            name="key"
            placeholder={t("translation:enter_key")}
          />
        </Form.Item>
      </div>
      <div className="w-1/12 mb-6 flex items-center justify-center">
        <IconBuilder icon="ArrowRight" size={36} />
      </div>
      <div className="w-5/12">
        <Form.Item hasFeedback={false} name="dataType">
          <Select size="large" name="dataType">
            <Select.Option value="Number">Number</Select.Option>
            <Select.Option value="String">String</Select.Option>
            <Select.Option value="DateTime">Datetime</Select.Option>
          </Select>
        </Form.Item>
      </div>
      <div className="w-2/12 flex gap-2">
        <SubmitButton
          type="primary"
          size="large"
          block
          disabled={!dirty || !isValid}
        >
          {t("translation:save")}
        </SubmitButton>
        {mapping && (
          <HeroButton
            onClick={removeMappingHandler}
            color="default"
            size="md"
            variant="bordered"
            isIconOnly
            className="hover:border-red-500 bg-white"
          >
            <IconBuilder size={18} color="#e00000" icon="Delete" />
          </HeroButton>
        )}
      </div>
    </div>
  );
}

/**
 * Component for a text type mapping.
 */
export function TextType({
  mapping,
  removeMapping,
  dirty,
  isValid,
}: {
  mapping?: Mapping;
  removeMapping?: (mapping: Mapping) => void;
  dirty: boolean;
  isValid: boolean;
}) {
  const { t } = useTranslation();
  /**
   * Remove the mapping. This is only available when editing a mapping.
   */
  const removeMappingHandler = useCallback(() => {
    if (removeMapping) {
      removeMapping(mapping);
    }
  }, [removeMapping, mapping]);
  return (
    <div className="flex gap-4 mt-6">
      {mapping && (
        <div className="w-1/12 mb-6 flex items-center justify-center">
          {t("translation:text")}:
        </div>
      )}
      <div className="w-7/12">
        <Form.Item name="key">
          <Input
            size="large"
            name="key"
            placeholder={t("translation:enter_key")}
          />
        </Form.Item>
      </div>
      <div className="w-1/12 mb-6 flex items-center justify-center">
        <IconBuilder icon="ArrowRight" size={36} />
      </div>
      <div className="w-5/12">
        <Form.Item name="value">
          <Input
            size="large"
            name="value"
            placeholder={t("translation:enter_value")}
          />
        </Form.Item>
      </div>
      <div className="w-3/12 flex gap-2">
        <SubmitButton
          type="primary"
          size="large"
          block
          disabled={!dirty || !isValid}
        >
          {t("translation:save")}
        </SubmitButton>
        {mapping && (
          <HeroButton
            onClick={removeMappingHandler}
            color="default"
            size="md"
            variant="bordered"
            isIconOnly
            className="hover:border-red-500 bg-white"
          >
            <IconBuilder size={18} color="#e00000" icon="Delete" />
          </HeroButton>
        )}
      </div>
    </div>
  );
}
