import { useQuery } from "@tanstack/react-query";
import { Checkbox, Col, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import { PowerPickTimeZoneToLocal } from "/app/src/components/generic/formatting/dateTime";
import { HistoryTypes } from "/app/src/constants/types";
import { Transaction } from "/app/src/models";
import { transactionService } from "/app/src/services";
import { useNavigate } from "react-router-dom";
import ClickableCell from "/app/src/components/generic/components/clickableCell";
import HeroSpin from "/app/src/components/HeroUi/Spin";

/**
 * Returns a text representation of the export state type
 * @param exportStateType number to convert to string
 * @returns
 */
const getStatusProps = (exportStateType) => {
  switch (exportStateType) {
    case 4:
      return { colorClass: "text-green-500", text: "Success" };
    case 11:
    case 3:
      return { colorClass: "text-orange-500", text: "Sending" };
    case 5:
      return { colorClass: "text-red-500", text: "Failed" };
    case 10:
      return { colorClass: "text-orange-500", text: "Sending" };
    case 12:
      return {
        colorClass: "text-itempath-symbol",
        text: "Awaiting Approval",
      };
    default:
      return {
        colorClass: "text-black",
        text: `Unknown Type: ${exportStateType}`,
      };
  }
};

/**
 * Renders a status badge based on the export state type.
 * @param {string} exportStateType - The export state type.
 * @returns {JSX.Element} The rendered status badge.
 */
const StatusBadge = ({ exportStateType }) => {
  const { colorClass, text } = getStatusProps(exportStateType);

  return <span className={colorClass}>{text}</span>;
};

/**
 * Shows a single row of a failed transaction
 * Each row controls its own retrying state using useQuery and useEffect
 */
export default function TransactionRow({
  transaction,
  selectedRowIds,
  setSelectedRowIds,
  ordersRetrying,
  setOrdersRetrying,
  exportStateType,
}: {
  transaction: Transaction;
  selectedRowIds: string[];
  setSelectedRowIds: (selectedRowIds: string[]) => void;
  ordersRetrying: string[];
  setOrdersRetrying: (ordersRetrying: string[]) => void;
  exportStateType: 5 | 12;
}) {
  const [enabled, setEnabled] = useState<boolean>(false);
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ["failedTransaction", transaction.id],
    queryFn: () => {
      return transactionService.getSingle(transaction.id);
    },
    initialData: { transaction },
    enabled,
    refetchInterval: 3000,
  });

  useEffect(() => {
    //if the transaction is in a retryable state, enable the query to fetch
    //every three seconds
    if ([3, 10, 11].includes(data.transaction?.exportStateType)) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [data.transaction?.exportStateType]);

  useEffect(() => {
    //if the transaction failed, remove it from the retrying list
    if (
      data.transaction?.exportStateType !== 10 &&
      ordersRetrying.includes(data.transaction.id)
    ) {
      setOrdersRetrying(
        ordersRetrying.filter((id) => id !== data.transaction.id),
      );
    }
  }, [
    data.transaction?.exportStateType,
    ordersRetrying,
    data.transaction.id,
    setOrdersRetrying,
  ]);

  const transactionIdClick = useCallback(
    (transactionId: string) => {
      navigate(`/explorer/transactions/${transactionId}`);
    },
    [navigate],
  );

  const actionsClicked = useCallback(() => {
    if (selectedRowIds.includes(transaction.id)) {
      setSelectedRowIds(
        selectedRowIds.filter(
          (selectedRowId) => selectedRowId !== transaction.id,
        ),
      );
    } else {
      setSelectedRowIds([...selectedRowIds, transaction.id]);
    }
  }, [selectedRowIds, setSelectedRowIds, transaction.id]);

  return (
    <Row gutter={20} className="transaction-row">
      <Col span={6} className="name">
        <ClickableCell
          cellValue={data.transaction.id}
          clickFn={transactionIdClick}
          parameter={data.transaction.id}
        />
      </Col>
      <Col span={6} className="name">
        {data.transaction.orderName}
      </Col>
      <Col span={4}>
        {
          HistoryTypes.find((type) => type.value === data.transaction.type)
            ?.name
        }
      </Col>
      <Col span={2}>
        <StatusBadge exportStateType={data.transaction?.exportStateType} />
      </Col>
      <Col span={1}>
        {[3, 10, 11].includes(data.transaction?.exportStateType) && (
          <HeroSpin size="sm" />
        )}
      </Col>

      <Col span={4}>
        <PowerPickTimeZoneToLocal
          date={data.transaction.creationDate}
          format={"MMMM Do YYYY, h:mm:ss.SS a"}
        />
      </Col>
      <Col span={1} className="actions">
        <div className="titleActions">
          <Checkbox
            checked={selectedRowIds.includes(transaction.id)}
            onClick={actionsClicked}
            disabled={data.transaction?.exportStateType !== exportStateType}
          />
        </div>
      </Col>
    </Row>
  );
}
