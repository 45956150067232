import deleteIcon from "/app/src/components/generic/title/deleteIcon.svg";
import HeroButton from "/app/src/components/HeroUi/Button";
import HeroModal from "/app/src/components/HeroUi/Modal";
import ModalBuilder from "/app/src/components/HeroUi/helpers/ModalBuilder";
import { useDisclosure } from "@heroui/react";

const DeleteButton = ({
  onClick,
  disabled,
  modalHeader,
  modalBody,
}: {
  onClick: () => void;
  disabled?: boolean;
  modalHeader?: string;
  modalBody?: string;
}) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return modalHeader ? (
    <>
      <button className="deleteButton" onClick={onOpen} disabled={disabled}>
        <img src={deleteIcon} alt="Delete" />
      </button>
      <HeroModal
        disableAnimation={false}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top"
      >
        {ModalBuilder({
          warning: true,
          modalHeader,
          modalBody,
          modalFooter: (
            <>
              <HeroButton
                size="sm"
                color="default"
                variant="bordered"
                onClick={onOpenChange}
              >
                Cancel
              </HeroButton>
              <HeroButton size="sm" color="red" onClick={onClick}>
                Delete
              </HeroButton>
            </>
          ),
        })}
      </HeroModal>
    </>
  ) : (
    <button className="deleteButton" onClick={onClick} disabled={disabled}>
      <img src={deleteIcon} alt="Delete" />
    </button>
  );
};

export default DeleteButton;
